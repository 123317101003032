import React from 'react'
import { Link } from 'gatsby'
import Image from '../image/image'
import './event-list-item.sass'

const getImage = (image, ) => {
  if (image) {
    return (
      <div className="EventListItem__thumb--container">
        <Image image_url={image} alt={`Background image for ${data.title} article`} className="EventListItem__thumb"/>
      </div>
    )
  }
}

const Event = ({ data, ...props }) => {
    return (
      <article className="EventListItem">
        <Link className="EventListItem__link" to={data.path}>
          {getImage(data.image, data.title)}
          <div className="EventListItem__content px-4 pt-4">
            <h2 className="EventListItem__title h4">
              {data.title}
            </h2>
            <p className="EventListItem__text">
              {data.card.snippet}
            </p>
          </div>
          <div className="EventListItem__footer px-4 pb-4">
            <hr className="mt-0" />
            <div className="EventListItem__link-text d-flex align-items-center">
              <span className="flex-grow-1 k-text--mono small">
                {data.card.linktext
                  ? data.card.linktext
                  : 'Read more'}
              </span>
              <span>&rarr;</span>
            </div>
          </div>
        </Link>
      </article>)
}

export default Event

import React from 'react'
import { Link } from 'gatsby'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import './news-list-item.sass'

const getInternalLink = data => (
  <Link
    to={data.link.url}
    className="NewsListItem__link">
    <h4 className="NewsListItem__title">
      {data.title}
    </h4>
    <span className="k-text--mono small">
      {data.link.text}
    </span>
  </Link>
)

const getExternalLink = data => (
  <a
    className="NewsListItem__link"
    href={data.link.url}
    target="_blank"
    rel="noopener noreferrer">
    <h4 className="NewsListItem__title">
      {data.title}
    </h4>
    <span className="k-text--mono small">
      {data.link.text}
    </span>
    <span className="ml-2 small">
      &#8599;
    </span>
  </a>
)

const NewsListItem = ({ data, ...props }) => (
  <article
    className="NewsListItem py-4"
    onClick={ () => sendClickedEvent(`NewsListItem__${data.title}`) }
  >
    {
      data.link.external
        ? getExternalLink(data)
        : getInternalLink(data)
    }
  </article>
)

export default NewsListItem

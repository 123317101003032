import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import NewsListItem from './news-list-item'

const getNewsItems = () => {
  let data = useStaticQuery(
    graphql`
      query {
        mainYaml {
          news {
            title
            link {
              url
              text
              external
            }
          }
        }
      }
    `
  )
  return data.mainYaml.news.map((story, i) => {
    return (
      <NewsListItem
        key={i}
        data={story}/>
    )
  })
}

const NewsList = ({ data, ...props }) => (
  <aside className="NewsList">
    {getNewsItems()}
  </aside>
)

export default NewsList

import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import EventListItem from 'components/events/event-list-item'
import StickySidebar from 'components/sidebars/sticky-sidebar'
import NewsList from 'components/news/news-list'
import GetInTouch from 'components/get-in-touch/get-in-touch'

class EventsPage extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  getEvents() {
    return this.props.data.allEventsYaml.edges
      .filter(event => event.node.date >= new Date().setHours(0,0,0,0))
      .map((event, i) => {
        return (
          <EventListItem
            key={i}
            data={event.node}>
            {event.node.title}
          </EventListItem>
        )
      })
  }

  render() {
    return (
      <Layout
        bodyClass="k-reverse-header">
        <SEO
          title="Events - Hackathons, Meet-Ups and more"
          keywords={['Kortical events', 'AI events', 'AI hackathons', 'ML hackathons', 'ML events', 'Artificial Intelligence']} 
          description="Come join us for exciting machine learning events" />
        <div className="k-bg--sidebar">
          <div className="container-fluid">
            <div className="row">
              <div className="col k-bg--white k-section-pt--lg">
                <h1 className="k-text--heading-mono">Events</h1>
                <p className="h2">
                  Join us for our next event
                </p>
                <div className="k-article-grid my-5">
                  {this.getEvents()}
                </div>
                <div className="k-border--top p-3 mb-3 text-center small"
                  >No further events.
                </div>
              </div>
              <aside className="col-lg-auto k-section-pt--lg-sidebar k-bg--grey">
                <StickySidebar>
                  <h2 className="k-text--heading-mono">News</h2>
                  <NewsList />
                </StickySidebar>
              </aside>
            </div>
          </div>
        </div>
        <GetInTouch />
      </Layout>
    )
  }

}

export default EventsPage

export const pageQuery = graphql`
  query {
    allEventsYaml(
      sort: {
        fields: [date],
        order: DESC
      }
    ) {
      edges {
        node {
          date(formatString: "x")
          path
          title
          image
          card {
            snippet
            linktext
          }
        }
      }
    }
  }
`
